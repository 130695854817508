import { EnrollmentsStatistics } from 'src/app/models/enrollment/enrollment-statistics';
import { EnrollmentService } from 'src/app/services/enrollment/enrollment.service';
import { ScholasticYear } from 'src/app/models/scholastic-year.model';
import { Component, inject, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-enrollments-statistics',
  templateUrl: './enrollments-statistics.component.html',
  styleUrl: './enrollments-statistics.component.scss',
})
export class EnrollmentsStatisticsComponent implements OnInit {
  private _enrollmentService: EnrollmentService = inject(EnrollmentService);
  public graficoColonneDivisioneDipartimenti?: Chart;
  public graficoTortaDivisioneDipartimenti?: Chart;
  public graficoTortaDivisioneGenere?: Chart;
  public graficoTortaDivisioneSede?: Chart;
  public graficoLineeDivisioneMesi?: Chart;
  maxValue?: number;

  public year: string = ScholasticYear.getNext();
  public statistics?: EnrollmentsStatistics;
  public loading?: boolean = true;

  public limits = [
    { title: 'Secondaria di I Grado', value: 20 },
    { title: 'Liceo delle Scienze Umane', value: 50 },
    { title: 'Liceo Scientifico Sportivo', value: 50 },
    { title: 'Liceo Scientifico', value: 25 },
    { title: 'Liceo Linguistico Internazionale', value: 25 },
    { title: 'Liceo Classico', value: 25 },
    { title: 'ITEM', value: 25 },
  ];

  ngOnInit(): void {
    this.getData();
  }

  setYear(value: number): void {
    const years: string[] = this.year.split('-');
    this.year = `${parseInt(years[0]) + value}-${parseInt(years[1]) + value}`;
    this.getData();
  }

  private _setMaxValue(values?: number[]): void {
    if (values == undefined || values.length === 0) return;
    const setMaxValue: number = Math.max(...values);
    if (!this.maxValue || setMaxValue > this.maxValue) {
      this.maxValue = setMaxValue;
    }
  }

  async getData() {
    this.loading = true;
    this._enrollmentService.getStatistics(this.year).subscribe({
      next: (response) => {
        setTimeout(() => {
          this.loading = false;
          this.statistics = response.data as EnrollmentsStatistics;
          this._setMaxValue(
            response.data.dipartimenti.map((data: any) => data.iscritti.totale)
          );
          this._setMaxValue(
            response.data.dipartimenti
              .map((data: any) => data.dipartimento)
              .map(
                (dp: string) =>
                  this.limits.filter((l) => l.title == dp).at(0)?.value
              )
          );
          setTimeout(() => {
            this.graficoColonneDivisioneDipartimenti = new Chart(
              'graficoColonneDivisioneDipartimenti',
              {
                type: 'bar',
                data: {
                  labels: response.data.dipartimenti.map(
                    (data: any) => data.dipartimento
                  ),
                  datasets: [
                    {
                      label: 'Iscritti',
                      data: response.data.dipartimenti.map(
                        (data: any) => data.iscritti.totale
                      ),
                      borderWidth: 0,
                      backgroundColor: '#3699ce99',
                    },
                    {
                      label: 'Limite',
                      data: response.data.dipartimenti
                        .map((data: any) => data.dipartimento)
                        .map(
                          (dp: string) =>
                            this.limits.filter((l) => l.title == dp).at(0)
                              ?.value
                        ),
                      borderWidth: 0,
                      backgroundColor: '#3699ce50',
                    },
                  ],
                },
                options: {
                  responsive: true,
                  scales: {
                    y: {
                      beginAtZero: true,
                      max: this.maxValue ? this.maxValue + 5 : 0,
                    },
                  },
                },
              }
            );
            this.graficoTortaDivisioneDipartimenti = new Chart(
              'graficoTortaDivisioneDipartimenti',
              {
                type: 'pie',
                data: {
                  labels: response.data.dipartimenti.map(
                    (data: any) => data.dipartimento
                  ),
                  datasets: [
                    {
                      label: 'Iscrizioni',
                      data: response.data.dipartimenti.map(
                        (data: any) => data.iscritti.percentuale
                      ),
                      backgroundColor: [
                        '#FF3B30FF',
                        '#FF9500FF',
                        '#FFCC00FF',
                        '#34C759FF',
                        '#00C7BEFF',
                        '#30B0C7FF',
                        '#32ADE6FF',
                        '#007AFFFF',
                      ],
                      hoverOffset: 4,
                    },
                  ],
                },
                options: {
                  responsive: true,
                  plugins: {
                    title: {
                      display: true,
                      position: 'top',
                      text: 'Distribuzione in base al dipartimento',
                      color: '#000',
                    },
                    legend: {
                      display: false,
                    },
                  },
                },
              }
            );
            this.graficoTortaDivisioneGenere = new Chart(
              'graficoTortaDivisioneGenere',
              {
                type: 'pie',
                data: {
                  labels: ['Maschi', 'Femmine'],
                  datasets: [
                    {
                      label: ' ',
                      data: [
                        response.data.generale.dettagli.maschi.percentuale,
                        response.data.generale.dettagli.femmine.percentuale,
                      ],
                      backgroundColor: ['#34C759FF', '#00C7BEFF'],
                      hoverOffset: 4,
                    },
                  ],
                },
                options: {
                  responsive: true,
                  plugins: {
                    title: {
                      display: true,
                      position: 'top',
                      text: 'Distribuzione in base al genere',
                      color: '#000',
                    },
                    legend: {
                      display: false,
                    },
                  },
                },
              }
            );
            this.graficoTortaDivisioneSede = new Chart(
              'graficoTortaDivisioneSede',
              {
                type: 'pie',
                data: {
                  labels: ['Sede', 'Fuori Sede'],
                  datasets: [
                    {
                      label: ' ',
                      data: [
                        response.data.generale.dettagli.sede.percentuale,
                        response.data.generale.dettagli.fuori_sede.percentuale,
                      ],
                      backgroundColor: ['#5856D6FF', '#AF52DEFF'],
                      hoverOffset: 4,
                    },
                  ],
                },
                options: {
                  responsive: true,
                  plugins: {
                    title: {
                      display: true,
                      position: 'top',
                      text: 'Distribuzione in base alla residenza',
                      color: '#000',
                    },
                    legend: {
                      display: false,
                    },
                  },
                },
              }
            );

            const monthsIndexConverter: number[] = [
              8, 9, 10, 11, 0, 1, 2, 3, 4, 5, 6, 7,
            ];
            this.graficoLineeDivisioneMesi = new Chart(
              'graficoLineeDivisioneMesi',
              {
                type: 'line',
                data: {
                  labels: [
                    'Settembre',
                    'Ottobre',
                    'Novembre',
                    'Dicembre',
                    'Gennaio',
                    'Febbraio',
                    'Marzo',
                    'Aprile',
                    'Maggio',
                    'Giugno',
                    'Luglio',
                    'Agosto',
                  ],
                  datasets: [
                    {
                      label: 'Iscrizioni per mese',
                      data: Array(12)
                        .fill(() => '')
                        .map((_, i) => {
                          return (
                            response.data.generale.dettagli.mesi.at(
                              monthsIndexConverter.at(i)
                            )?.totale ?? 0
                          );
                        }),
                      fill: false,
                      borderColor: '#3699ce',
                      tension: 0.25,
                    },
                  ],
                },
                options: {
                  responsive: true,
                  scales: {
                    y: {
                      beginAtZero: true,
                    },
                  },
                  plugins: {
                    title: {
                      display: true,
                      position: 'top',
                      text: 'Distribuzione iscrizioni in base al mese',
                      color: '#000',
                    },
                    legend: {
                      display: false,
                    },
                  },
                },
              }
            );
          }, 100);
        }, 500);
      },
      error: (err) => {
        console.error('EnrollmentsStatistics Error:', err);
      },
    });
  }
}
